<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created() {
    this.$api.addPV();
    this.$store.getGlobalSet();
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba($color: #000000, $alpha: 0.4);
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
  }
  &:hover:-webkit-scrollbar-thumb {
    background-color: rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-track:hover {
    background-color: rgba($color: #000000, $alpha: 0.01);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  }
}
ul,
ol {
  list-style: none;
}
#app {
  color: #333;
  background-color: #f4f6fc;
}

.content {
  height: calc(100vh - 315px);
}
.body {
  width: 85vw;
  margin: 0 auto;
}
.move-frame {
  background-image: url("./assets/move/move_bg.jpg");
  background-size: 100% 100%;

  .content {
    min-width: 1100px;
  }
}

// .user-content {
//   line-height: 20px;

//   .desc {
//     font-size: 12px;
//     color: #666;
//   }
// }

@media (max-width: 1500px) {
  .content {
    height: calc(100vh - 270px);
  }
}
@media (max-width: 1400px) {
  .content {
    height: calc(100vh - 232px);
  }
}
</style>
