import Vue from "vue";
import Router from "vue-router";

import moveWater from "./moveWater"

Vue.use(Router);

const router = new Router({
  routes: [
    ...moveWater,
    {
      path: "/",
      name: "homepage",
      component: () => import("../views/homePage"),
    },
    {
      path: "/chance",
      name: "投资机会",
      component: () => import("../views/chance/chance"),
    },
    {
      path: "/route",
      name: "投资考察路线",
      component: () => import("../views/route/route"),
    },
    {
      path: "/policy",
      name: "政策",
      component: () => import("../views/policy/policy"),
    },
    {
      path: "/error",
      name: "500",
      component: () => import("../views/error/500"),
    },
    {
      path: "/notFound",
      name: "404",
      component: () => import("../views/error/404"),
    },
    { path: "*", redirect: "/notFound", hidden: true },
  ],
});
export default router;
