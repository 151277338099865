export default [
    {
        path: "/move/traffic",
        name: "黑水概况",
        component: () => import("../views/moveWater/traffic"),
    },
    {
        path: "/move/production",
        name: "产业概况",
        component: () => import("../views/moveWater/production"),
    },
    {
        path: "/move/resource",
        name: "优势资源",
        component: () => import("../views/moveWater/resource"),
    },
    {
        path: "/move/enterprise",
        name: "企业",
        component: () => import("../views/moveWater/enterprise"),
    },
    {
        path: "/move/ecology",
        name: "生态人文",
        component: () => import("../views/moveWater/ecology"),
    },
]