import request from "../request";

const api = {
  // 获取产业
  getIndustryDic(params) {
    return request({
      url: `blackwater/industry-dictionary/front/listAllChildrenAndParent`,
      method: "get",
      params,
    });
  },
  // 根据id获取产业
  getIndustryById(params) {
    return request({
      url: `blackwater/industry/getIndustryById`,
      method: "get",
      params,
    });
  },
  // 查询投资机会字典
  getInvestDic(params) {
    return request({
      url: `blackwater/invest-chance-dictionary/front/listAllChildrenAndParent`,
      method: "get",
      params,
    });
  },
  // 查询投资机会
  getInvestList(params) {
    return request({
      url: `blackwater/invest-chance`,
      method: "get",
      params,
    });
  },
  // 全局配置
  getSet(params) {
    return request({
      url: `web/sys-web-config/front`,
      method: "get",
      params,
    });
  },
  // 查询优势资源
  getResource(params) {
    return request({
      url: `blackwater/resources-detail/front/pageFrontList`,
      method: "get",
      params,
    });
  },
  // 查询所有优势资源
  getAllResource() {
    return request({
      url: `blackwater/resources-detail/front/frontListAll`,
      method: "get",
    });
  },
  // 查询企业
  getEnterprise(params) {
    return request({
      url: `blackwater/enterprise`,
      method: "get",
      params,
    });
  },
  // 查询企业动态
  getDynamic(params) {
    return request({
      url: `blackwater/enterprise-news/front`,
      method: "get",
      params,
    });
  },
  // 查询人才字典
  getIntroDic(params) {
    return request({
      url: `blackwater/talent-introduction-dictionary/front/listAllChildrenAndParent`,
      method: "get",
      params,
    });
  },
  // 查询招聘信息
  getIntroEmploy(params) {
    return request({
      url: `blackwater/talent-introduction-employ`,
      method: "get",
      params,
    });
  },
  // 查询人才指引
  getIntroList(params) {
    return request({
      url: `blackwater/talent-introduction`,
      method: "get",
      params,
    });
  },
  // 查询生态人文
  getEcological(params) {
    return request({
      url: `blackwater/ecological`,
      method: "get",
      params,
    });
  },
  // 查询考察路线
  getRouteList(params) {
    return request({
      url: `blackwater/exploration-route`,
      method: "get",
      params,
    });
  },
  // 查询政策
  getPolicy(params) {
    return request({
      url: `blackwater/policy`,
      method: "get",
      params,
    });
  },
  // 根据id查询政策
  getPolicyById(params) {
    return request({
      url: `blackwater/policy/getPolicyById`,
      method: "get",
      params,
    });
  },
  // 查询政策
  getInvestDicByName(params) {
    return request({
      url: `blackwater/invest-chance-dictionary/front/listSecDicAndData`,
      method: "get",
      params,
    });
  },
  // 增加网站访问量
  addPV() {
    return request({
      url: `blackwater/net-visits-times/insertOrUpdate`,
      method: "post",
      data: {},
    });
  },
  // 查看banner
  getBanner() {
    return request({
      url: `blackwater/sys-banner/front`,
      method: "get",
    });
  },
};

export default {
  ...api,
};
