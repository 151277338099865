import { defineStore } from "pinia";
import api from "../util/api";

export const Store = defineStore("main", {
    state: () => {
        return {
            globalSet: {},   // 全局配置
        }
    },
    actions: {
        // 获取全局配置
        async getGlobalSet() {
            const res = await api.getSet();
            this.globalSet = res.data;
        },
    }
})